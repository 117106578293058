@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply px-6 py-2.5 bg-blue-600 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  /* .btn-primary{@apply text-white bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 font-bold text-xs px-6 py-2.5 text-center leading-tight uppercase rounded shadow-md} */
  /* .btn-primary {@apply mr-2 my-1 uppercase tracking-wider px-2 text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white border text-xs font-bold rounded py-1 transition transform duration-500 cursor-pointer} */
  .resize {
    resize: both;
    overflow: auto;
  }
  .responsive-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  .responsive-iframe > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* min-height: 100vh; */
}

@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/axios-progress-bar/dist/nprogress.css";

/* @import url('https://fonts.googleapis.com/css2?family=Anybody:ital,wght@1,300&family=Asap+Condensed:ital@1&family=Lato:ital@1&family=League+Gothic&family=Oswald:wght@300&display=swap');
*{font-family: 'Asap Condensed', sans-serif;} */

/* @import url('https://fonts.googleapis.com/css2?family=Anybody:ital,wght@1,300&family=Lato:ital@1&family=League+Gothic&family=Oswald:wght@300&display=swap');
* {font-family: 'Oswald', sans-serif;} */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

/*Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: blue white;
  scroll-behavior: smooth;
}
/*Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 2px solid white;
}

.Loading {
  background: url(./assets/images/bg6.webp) no-repeat center center fixed;
  background-size: cover;
}

.logoLoading {
  animation-duration: 3s;
  animation-name: slideIn;
}

@keyframes slideIn {
  0% {
    margin-bottom: 100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #ca3939;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #0004ff;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
